import type { i18n as I18nInstance } from 'i18next'

export const getValueByPath = (obj: any, path: string): any =>
  path.split('.').reduce((acc, key) => acc?.[key], obj)

export const flattenKeys = (obj: any, prefix = ''): Record<string, any> => {
  let result: Record<string, any> = {}

  Object.entries(obj).forEach(([key, value]) => {
    const path = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object' && value !== null) {
      Object.assign(result, flattenKeys(value, path))
    } else {
      result[path] = value
    }
  })

  return result
}

export const overrideKeysFromAlphaUI = (i18n: I18nInstance) => {
  i18n.on('languageChanged', async (lang) => {
    let alphaUIBundle = i18n.getResourceBundle(lang, 'alpha-ui')

    if (!alphaUIBundle) {
      const fallbackLng = i18n.options.fallbackLng as string || 'en'

      await i18n.loadNamespaces('alpha-ui')
      await i18n.loadLanguages(fallbackLng)

      alphaUIBundle = i18n.getResourceBundle(fallbackLng, 'alpha-ui')

      if (!alphaUIBundle) return
    }

    Object.entries(alphaUIBundle).forEach(([targetNS, overridesInNamespace]) => {

      const flatOverrides = flattenKeys(overridesInNamespace)
      const originalBundle = i18n.getResourceBundle(lang, targetNS)
      if (!originalBundle) return

      Object.entries(flatOverrides).forEach(([keyPath, newValue]) => {
        const existingValue = getValueByPath(originalBundle, keyPath)

        if (existingValue !== undefined) {
          i18n.addResource(lang, targetNS, keyPath, newValue)
        }
      })
    })
  })
}
