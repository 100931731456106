// TODO: maybe split into multiple components. exported badly anyway
import React, { useMemo, useLayoutEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { useLocation, Link, matchPath, useHistory } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faHomeAlt, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { Breadcrumbs } from '@kc/alphabrands-component-library'

import { BreadcrumbsStyles, LeftButtonContainer } from './BreadcrumbsNav.styles'
import useScreenSize from '../../hooks/useScreenSize'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'

type BreadcrumbsNavProps = { routes: RoutePathProps; hiddenRoutes?: string[] }

const BreadcrumbsNav: React.FC<BreadcrumbsNavProps> = ({ routes, hiddenRoutes }) => {
  const { crumbs: customCrumbs } = useBreadcrumbs()
  const { pathname } = useLocation()
  const { isMobile } = useScreenSize()
  const { brand, storeUrl } = useTheme()

  const customCrumbsLength = React.Children.toArray(customCrumbs).length
  const matchedRoutes = useMemo(
    () =>
      routes.reduce<RoutePathProps>((prev, route) => {
        const match = matchPath(pathname, { path: route.path })
        if (match && route.title && shouldShowCrumb(match.url, hiddenRoutes)) {
          return [...prev, { title: route.title, path: match.url }]
        }
        return prev
      }, []),
    [pathname, routes, hiddenRoutes],
  )

  const renderLeftIcon = (): React.ReactElement => {
    const navigatedMoreThanOneLevel = matchedRoutes.length > 1 || customCrumbsLength > 1
    if (isMobile && navigatedMoreThanOneLevel) {
      return <BackButton />
    }
    return <HomeLink external={brand === 'demag' || brand === 'swf'} href={storeUrl} />
  }

  const renderMatchedCrumbs = () =>
    matchedRoutes.map(({ path, title }, index) =>
      index === matchedRoutes.length - 1 ? (
        <span key={path}>{title}</span>
      ) : (
        <Link key={path} to={path}>
          {title}
        </Link>
      ),
    )

  const renderCrumbs = () => {
    if (isMobile) {
      return <span>{matchedRoutes[0].title}</span>
    }
    return customCrumbs || renderMatchedCrumbs()
  }

  const showBreadcrumbs = matchedRoutes.length > 0
  return (
    <BreadcrumbsStyles className="breadcrumbs-menu">
      {showBreadcrumbs && (
        <Breadcrumbs data-testid="breadcrumbs-menu">
          {renderLeftIcon()}
          {renderCrumbs()}
        </Breadcrumbs>
      )}
    </BreadcrumbsStyles>
  )
}

const BackButton: React.FC = () => {
  const history = useHistory()
  return (
    <LeftButtonContainer>
      <button aria-label="Go back" onClick={() => history.goBack()}>
        <Icon icon={faChevronLeft} size="lg" />
      </button>
    </LeftButtonContainer>
  )
}

export const HomeLink: React.FC<{ external: boolean; href?: string }> = ({ external, href }) => {
  const icon = (
    <Icon
      icon={faHomeAlt}
      size="lg"
      color="#282B2E"
      style={{ marginTop: '-7px', display: 'block' }}
    />
  )
  return (
    <LeftButtonContainer>
      {external && href ? (
        <a href={href} aria-label="Home">
          {icon}
        </a>
      ) : (
        <Link to="/" aria-label="Home">
          {icon}
        </Link>
      )}
    </LeftButtonContainer>
  )
}

const CustomBreadCrumbs: React.FC & BreadcrumbsComposition = ({ children }) => {
  const { setCrumbs } = useBreadcrumbs()

  useLayoutEffect(() => {
    setCrumbs(children)

    return () => {
      setCrumbs(null)
    }
  }, [setCrumbs, children])

  return null
}

const Crumb: React.FC<CrumbProps> = ({ to, text }) => {
  if (!text) {
    return (
      <ContentLoader
        data-testid="breadcrumb-loader"
        speed={2}
        width={150}
        height={15}
        viewBox="0 0 165 15"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect rx="3" ry="3" width="150" height="15" />
      </ContentLoader>
    )
  }
  return to ? <Link to={to}>{text}</Link> : <span>{text}</span>
}

function shouldShowCrumb(url: string, hiddenRoutes: string[] = []): boolean {
  return !hiddenRoutes.some((u) => u === url)
}

type RoutePathProps = { title?: string; path: string }[]

type CrumbProps = {
  to?: string
  text: string | null | undefined
}

type BreadcrumbsComposition = {
  Crumb: React.FC<CrumbProps>
}

CustomBreadCrumbs.Crumb = Crumb
export { CustomBreadCrumbs as Breadcrumbs }
export default BreadcrumbsNav
